import { AllPost, PostType } from '@memberspot/shared/model/course';

export const exampleLogoUrl = 'assets/images/defaults/header-logo-1.png';

export const exampleCourseUrl = 'assets/images/defaults/thumb-kurs.jpg';
export const exampleChapterUrl = 'assets/images/defaults/thumb-kapitel.jpg';

export const exampleLectureUrl = 'assets/images/defaults/thumb-audio_video.jpg';
export const exampleAudiopostUrl =
  'assets/images/defaults/thumb-audio_video.jpg';

export const exampleTextpostUrl = 'assets/images/defaults/thumb-text.jpg';
export const exampleIframePostUrl = 'assets/images/defaults/thumb-code.jpg';

export const exampleCertificatePostUrl =
  'assets/images/defaults/thumb-zertifikat.jpg';

export const exampleExamUrl = 'assets/images/defaults/thumb-exam.jpg';

export const exampleHeaderProfileSlotUrl =
  'assets/images/examples/businessman.jpg';

export const imageLoadErrUrl = 'assets/images/defaults/loaderr.png';

export const certificateLoading = 'assets/images/defaults/certloading.jpg';

export const defaultAppIcon = 'assets/images/defaults/app-icon.png';
export const defaultMenuIcon = 'assets/images/defaults/menu_icon.png';

export const MemberhubLogoGrayImageUrl = 'assets/images/logo-gray.png';

export const PosttypeExampleUrls: Record<PostType | 'chapter', string | null> =
  {
    [PostType.LECTURE]: exampleLectureUrl,
    [PostType.PDF]: exampleTextpostUrl,
    [PostType.AUDIOPOST]: exampleAudiopostUrl,
    [PostType.TEXTPOST]: exampleTextpostUrl,
    [PostType.EXAM]: exampleExamUrl,
    [PostType.IFRAMEPOST]: exampleIframePostUrl,
    [PostType.CERTIFICATE]: exampleCertificatePostUrl,
    [PostType.SUBCHAPTER]: null,
    chapter: exampleChapterUrl,
  };

export const getPostImg = (post: AllPost) => {
  if (post?.thumbnailUrl) {
    return post.thumbnailUrl;
  }

  if (post?.type === PostType.LECTURE && post?.video?.thumbnail) {
    return post.video.thumbnail;
  }

  if (post.type) {
    return PosttypeExampleUrls[post.type];
  }

  return null;
};

export const getPostImgShort = (post: AllPost) => {
  if (post?.type === PostType.LECTURE && post?.video?.thumbnail) {
    return post.video.thumbnail;
  }

  if (post.type) {
    return PosttypeExampleUrls[post.type];
  }

  return exampleLectureUrl;
};
