import { Thumbnail, UploadFile, Video } from '@memberspot/shared/model/file';

import { Available, Drip, NextPrevIds, NoProgress } from './availability.model';
import { CertificateBase, CertificateFont } from './certificate.model';
import { FormField } from './formfield.model';
import { PostProgress } from './progress.model';
import { StateType } from './state.model';

export enum PostType {
  LECTURE = 'lecture',
  AUDIOPOST = 'audiopost',
  TEXTPOST = 'textpost',
  SUBCHAPTER = 'subchapter',
  IFRAMEPOST = 'iframe',
  EXAM = 'exam',
  CERTIFICATE = 'certificate',
  PDF = 'pdf',
}

export interface PostForm {
  type: PostType;
  name: string;
}

export interface PostCustomName {
  customName?: string;
}

interface PostFormFields {
  formFields?: FormField[];
}

export interface PostFormGroups<T> {
  formGroups?: Array<T>;
}

export interface Post extends PostForm {
  id: string;

  state?: StateType;
  description?: string;
  tags?: string[] | null;
  expanded?: boolean;

  parent?: string | null;
  priority: number;

  isDemo?: boolean;
  thumbnailUrl?: string | null;

  isRequired?: boolean;
  codeRequired?: boolean;
  completeCode?: string;

  cta?: boolean;
  ctaBtn?: CtaButton;
}

export interface ExtendedPost extends Post {
  content?: string;
  htmlContent?: boolean;
  editorV2?: boolean;
  files?: Record<string, PostFile>;
  thumbnail?: Thumbnail;

  numberOfPosts?: number;
  postNumber?: number;

  completedEvents?: string[];
}

export interface CtaButton {
  btnText: string;
  url: string;
  customColors: boolean;
  btnColor?: string;
  color?: string;
}

export type PostFile = Omit<UploadFile, 'name' | 'fullPath' | 'linkedTo'>;

export interface Lecture extends ExtendedPost {
  type: PostType.LECTURE;
  video?: Video;
  blockVideoSkipping?: boolean;
  videoChapters?: string;
}

export interface ExamPost extends ExtendedPost {
  type: PostType.EXAM;
  examId?: string | null;
}

// Certificate Post
export interface CertificatePost
  extends ExtendedPost,
    PostCustomName,
    PostFormFields,
    PostFormGroups<{
      id: string;
      var: string | null;
      font: CertificateFont | null;
    }> {
  type: PostType.CERTIFICATE;
  certificate?: CertificateBase | null;
  forwardCertificate?: boolean;
  certificateForwardEmail?: string;
}

export interface AudioPost extends ExtendedPost {
  type: PostType.AUDIOPOST;
  audio?: PostFile;
}

export interface PdfPost extends ExtendedPost {
  type: PostType.PDF;
  pdf?: PostFile;
}

export interface IframePost extends ExtendedPost {
  type: PostType.IFRAMEPOST;
  iFrameContent?: string;
}

export interface SubChapter extends ExtendedPost {
  type: PostType.SUBCHAPTER;
  driptime?: number;
  children?: AllPost[];
}

export interface TextPost extends ExtendedPost {
  type: PostType.TEXTPOST;
}

export type AllPost = SubChapter | AllExtPost;
export type AllExtPost =
  | Lecture
  | TextPost
  | AudioPost
  | IframePost
  | ExamPost
  | CertificatePost
  | PdfPost;

export type AllPostCombined = Omit<SubChapter, 'type' | 'files'> &
  Omit<Lecture, 'type' | 'files'> &
  Omit<TextPost, 'type' | 'files'> &
  Omit<AudioPost, 'type' | 'files'> &
  Omit<PdfPost, 'type' | 'files'> &
  Omit<IframePost, 'type' | 'files'> &
  Omit<ExamPost, 'type' | 'files'> &
  Omit<CertificatePost, 'type' | 'files'> & {
    type: PostType;
    postId: string;
    files?: PostFile[];
  };

export type AllPostWChapterId = AllPost & { chapterId: string };

export const PostTitles: Record<PostType, string> = {
  [PostType.LECTURE]: 'admin.post.title.lecture',
  [PostType.SUBCHAPTER]: 'admin.post.title.subchapter',
  [PostType.TEXTPOST]: 'admin.post.title.textpost',
  [PostType.IFRAMEPOST]: 'admin.post.title.iframepost',
  [PostType.EXAM]: 'admin.post.title.exam',
  [PostType.AUDIOPOST]: 'admin.post.title.audiopost',
  [PostType.CERTIFICATE]: 'admin.post.title.certificate',
  [PostType.PDF]: 'admin.post.title.pdf',
};

export const createNotIncludedPost = (post: AllExtPost): AllExtPost => ({
  id: post.id,
  name: post.name,
  priority: post.priority,
  state: post.state,
  parent: post.parent || null,
  type: post.type as any,
  description: post.description || '',
  thumbnailUrl: post.thumbnailUrl
    ? post.thumbnailUrl
    : post.type === PostType.LECTURE && post.video?.thumbnail
      ? post.video.thumbnail
      : '',
  numberOfPosts: post.numberOfPosts,
  postNumber: post.postNumber,
});

export const isPost = (post: AllPost): post is AllExtPost =>
  post.type !== PostType.SUBCHAPTER;

export type AllPostProgress = AllExtPost & PostProg;

export type AllPostDrip = AllPostProgress &
  Drip &
  Available &
  NextPrevIds &
  NoProgress;
export type AllPostAvailable = AllPostDrip;

export type LectureProg = Lecture &
  PostProg<Lecture> &
  Drip &
  Available &
  NextPrevIds;

export interface PostProg<T extends AllExtPost = any> {
  progress?: PostProgress<T>;
}
